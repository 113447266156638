import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import {Form,Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetProductDetailsQuery, useCreatereviewMutation } from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Rating from '../components/Rating';
import Meta from '../components/Meta';
import { addToCart } from '../slices/cartSlice';

const ProductScreen = () => {
  const { id: productId } = useParams();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');





  const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);

  const [createReview, { isLoading: loadingProductReview }] = useCreatereviewMutation();

  const { userInfo } = useSelector((state) => state.auth);

  
  const addToCartHandler = () => {
    dispatch(addToCart({...product, qty }));
    navigate('/cart');
  }

  const submitHandler = async (e) => {
    
    try {
      e.preventDefault();
    await createReview({
      productId,
      rating,
      comment
    }).unwrap();
    refetch();
    toast.success('Review submitted');
    setRating(0);
    setComment('');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }
  return (
    <>
    <Link className='btn btn-light my-3' to="/">Geri Dön</Link>

    { isLoading ? (
      <Loader />
    ) : error ? (
      <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) : (
      <>
      <Meta title={product.name}/>
      <Row>
      <Col md={5}>
        <Image src={product.image} alt={product.name} fluid/>
      </Col>
      <Col md={4}>
        <ListGroup>
          <ListGroup.Item>
              <h3>{product.name}</h3>
          </ListGroup.Item>
          <ListGroup.Item>
              <div>{product.name}</div>
          </ListGroup.Item>
          <ListGroup.Item>
              {product.description}
          </ListGroup.Item>
          <ListGroup.Item>
              <Rating value={product.rating} text={` ${product.numReviews} yorum` }/>
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col md={3}>
        <Card>
          <ListGroup>
              <ListGroup.Item>
                  <Row>
                      <Col>Fiyat:</Col>
                      <Col>
                      <strong>₺{product.price}</strong>
                      </Col>
                  </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                  <Row>
                      <Col>Durum:</Col>
                      <Col>
                      <strong>{product.countInStock > 0 ? 'Stokta' : 'Stokta Yok'}</strong>
                      </Col>
                  </Row>
              </ListGroup.Item>

              {product.countInStock > 0 && (
                <ListGroup.Item>
                  <Row>
                    <Col>Miktar:</Col>
                    <Col>
                      <Form.Control
                        as='select'
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}>
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x+1} value={x+1}>
                              {x+1}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}

              <ListGroup.Item>
                  <Button 
                   className='btn btn-block btn-dark'
                   type='button' 
                   disabled={product.countInStock === 0}
                   onClick={addToCartHandler}>
                      Sepete Ekle
                  </Button>
              </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
      </Row>
      <Row className='review'>
        <Col md={6}>
          <h2>Değerlendirmeler</h2>
          {product.reviews.length === 0 && <Message>Henüz Değerlendirilmemiş</Message>}
          <ListGroup variant='flush'>
            {product.reviews.map(review => (
              <ListGroup.Item key={review._id}> 
                <strong>{review.name}</strong>
                <Rating value={review.rating} />
                <p>{review.createdAt.substring(0,10)}</p>
                <p>{review.comment}</p>
              </ListGroup.Item>
            ))}
              <ListGroup.Item>
                <h2>Bir Değerlendirme Yap</h2>

                {loadingProductReview && <Loader/>}
                {userInfo ? (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId='rating' className='my-2'>
                      <Form.Label>Değerlendirme</Form.Label>
                      <Form.Control as='select' value={rating} onChange={(e) => setRating(Number(e.target.value))}>
                        <option value=''>Seçiniz...</option>
                        <option value='1'>1 </option>
                        <option value='2'>2 </option>
                        <option value='3'>3 </option>
                        <option value='4'>4 </option>
                        <option value='5'>5 </option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='comment' className='my-2'>
                      <Form.Label>Yorum</Form.Label>
                      <Form.Control as='textarea' row='3' value={comment} onChange={(e) => setComment(e.target.value)}>
                      </Form.Control>
                    </Form.Group>
                    <Button disabled={loadingProductReview} type='submit' variant='primary' className='btn-block btn-dark'>
                    Submit

                    </Button>
                  </Form>
                ) : (
                  <Message>Please <Link to='/login'>Sign In</Link> to write a review{''}</Message>
                )}
              </ListGroup.Item>
          </ListGroup>
        </Col>

      </Row>
      </>
    )}

    
    
    </>
  );
};

export default ProductScreen;