import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetUserAddressesQuery, useCreateUserAddressMutation } from '../slices/usersApiSlice';
import { saveShippingAddress, saveBillingAddress } from '../slices/cartSlice';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';

const ShippingScreen = () => {
  const { data: addresses, error, isLoading, refetch } = useGetUserAddressesQuery();
  const [createUserAddress] = useCreateUserAddressMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedAddress, setSelectedAddress] = useState({
    addressName: '',
    address: '',
    city: '',
    postalCode: '',
    country: ''
  });

  const [billingAddress, setBillingAddress] = useState({
    addressName: '',
    address: '',
    city: '',
    postalCode: '',
    country: ''
  });
  const [isExistingAddress, setIsExistingAddress] = useState(false);
  const [isSameAsShipping, setIsSameAsShipping] = useState(true);
  const [isBillingExistingAddress, setIsBillingExistingAddress] = useState(true);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // Handle form submission
    if(!isExistingAddress){
      await createUserAddress(selectedAddress);
    }
    if(!isSameAsShipping && !isBillingExistingAddress){
      await createUserAddress(billingAddress);
    }
    dispatch(saveShippingAddress(selectedAddress));
    if(!isSameAsShipping){
      dispatch(saveBillingAddress(billingAddress));
    } else {
      dispatch(saveBillingAddress(selectedAddress));
    }
    navigate('/placeorder'); // Example navigation to next step
  };

  const handleAddressChange = (e, setAddress, setExistingAddress) => {
    const selected = addresses.find((addr) => addr.addressName === e.target.value);
    if(selected){
      setExistingAddress(true);
      setAddress(selected);
    }else{
      setExistingAddress(false);
      setAddress({
        addressName: '',
        address: '',
        city: '',
        postalCode: '',
        country: ''
      });
    }
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Teslimat</h1>
      <Form onSubmit={submitHandler}>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error.message}</p>
        ) : addresses && addresses.length > 0 && (
          <Form.Group controlId="selectAddressName" className="my-2">
            <Form.Label>Select Address</Form.Label>
            <Form.Control
              as="select"
              value={selectedAddress.addressName}
              onChange={(e) => handleAddressChange(e, setSelectedAddress, setIsExistingAddress)}
            >
              <option value="">Select a saved address</option>
              {addresses.map((addr) => (
                <option key={addr._id} value={addr.addressName}>
                  {addr.addressName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group controlId="addressName" className="my-2">
          <Form.Label>Address Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address name"
            value={selectedAddress.addressName}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, addressName: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>
        <Form.Group controlId="address" className="my-2">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={selectedAddress.address}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, address: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>
        <Form.Group controlId="city" className="my-2">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            value={selectedAddress.city}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, city: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>
        <Form.Group controlId="postalCode" className="my-2">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter postal code"
            value={selectedAddress.postalCode}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, postalCode: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>
        <Form.Group controlId="country" className="my-2">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter country"
            value={selectedAddress.country}
            onChange={(e) =>
              setSelectedAddress({ ...selectedAddress, country: e.target.value })
            }
            disabled={isExistingAddress}
            required={!isExistingAddress}
          />
        </Form.Group>

        <Form.Group controlId="billingSameAsShipping" className="my-2">
          <Form.Check
            type="checkbox"
            label="Billing address same as shipping"
            checked={isSameAsShipping}
            onChange={(e) => setIsSameAsShipping(e.target.checked)}
            />
        </Form.Group>
        {!isSameAsShipping && (
          <>
            <h2>Billing Address</h2>
            {addresses && addresses.length > 0 && (
            <Form.Group controlId="selectBillingAddressName" className="my-2">
              <Form.Label>Select Address</Form.Label>
                <Form.Control
                  as='select'
                  value={billingAddress.addressName}
                  onChange={(e) => handleAddressChange(e, setBillingAddress, setIsBillingExistingAddress)}
                  >
                  <option value="">Select a saved address</option>
                  {addresses.map((addr) => (
                    <option key={addr._id} value={addr.addressName}>
                      {addr.addressName}
                    </option>
                    ))}
                  </Form.Control>
                  </Form.Group> 
            )}
             <Form.Group controlId="billingAddressName" className="my-2">
              <Form.Label>Address Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address name"
                value={billingAddress.addressName}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, addressName: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingAddress" className="my-2">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                value={billingAddress.address}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, address: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingCity" className="my-2">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter city"
                value={billingAddress.city}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, city: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingPostalCode" className="my-2">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter postal code"
                value={billingAddress.postalCode}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, postalCode: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
            <Form.Group controlId="billingCountry" className="my-2">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter country"
                value={billingAddress.country}
                onChange={(e) =>
                  setBillingAddress({ ...billingAddress, country: e.target.value })
                }
                disabled={isBillingExistingAddress}
                required={!isBillingExistingAddress}
              />
            </Form.Group>
          </>
        )}
        <Button type="submit" variant="primary" className="my-2 btn-dark">
          Kaydet ve İlerle
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;
