import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, ListGroup, Image, Card } from  "react-bootstrap";
import CheckoutSteps from '../components/CheckoutSteps';
import Message from '../components/Message';
import { useInitializeCheckoutFormMutation } from '../slices/paymentApiSlice'; // Adjust the import as necessary



const PlaceOrderScreen = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const [initializeCheckoutForm, { isLoading, isError, error }] = useInitializeCheckoutFormMutation();


  useEffect(() => {
    if (!cart.shippingAddress?.address || !cart.billingAddress?.address) {
      navigate('/shipping');
    }
  }, [cart.shippingAddress?.address, cart.billingAddress?.address, navigate]);
  
  const placeOrderHandler = async () => {
    try {
      const paymentData = {
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        billingAddress: cart.billingAddress,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        itemsPrice: cart.itemsPrice,
        totalPrice: cart.totalPrice,
      };
  
      const response = await initializeCheckoutForm(paymentData).unwrap();
      console.log('Response from API:', response);
  
      if (response && response.checkoutFormContent) {
        navigate('/payment', { state: { checkoutFormContent: response.checkoutFormContent } });
      } else {
        console.error('Failed to get checkout form content from response');
      }
    } catch (error) {
      console.error('Failed to initialize checkout form:', error);
    }
  };

  return (
    <>
    <CheckoutSteps step1 step2 step3 />
    <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Shipping Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},
                {cart.shippingAddress.postalCode}, {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Billing</h2>
              <p>
                <strong>Billing Address:</strong>
                {cart.billingAddress.address}, {cart.billingAddress.city},
                {cart.billingAddress.postalCode}, {cart.billingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>OrderItems</h2>
              <p>
                <strong>Method:</strong>
                {cart.cartItems.length === 0 ? (
                  <Message>Your Cart is Empty</Message>
                ): (
                  <ListGroup>
                    {cart.cartItems.map((item, index)=> (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col md={1}>
                            <Image 
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                            />
                          </Col>
                          <Col>
                            <Link to={`products/${item.product}`}>{item.name}</Link>
                          </Col>
                          <Col md={4}>
                            { item.qty } x ${item.price} = ${item.qty
                            * item.price}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Order Summary</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Items:</Col>
                <Col>
                  ${ cart.itemsPrice }
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Shipping:</Col>
                <Col>
                  ${ cart.shippingPrice }
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Tax:</Col>
                <Col>
                  ${ cart.taxPrice }
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Total:</Col>
                <Col>
                  ${ cart.totalPrice }
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Button 
                type='button'
                className='btn-block btn-dark'
                disabled={cart.cartItems.length === 0}
                onClick={placeOrderHandler}
                >
                  Proceed to Payment
              </Button> 
             </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
    </Row>
    </>
  )
}

export default PlaceOrderScreen;